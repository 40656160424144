import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useHttpClient } from '../hooks/http-hook';
import Stops from '../components/Stops';
import StopTimes from '../components/StopTimes';
import LineHeader from '../components/LineHeader';
import './LineDetails.css';
import TimeDescription from '../components/TimeDescription';
import LoadingSpinner from '../components/LoadingSpinner';
import LineDialog from '../components/LineDialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const LineDetails = () => {

  const restrictedLines = {
    '60h': 'v',
    '36e': 'v',
    '10a': 'o',
    '125': 'o',
  };

  const { line_name: initialLineName, sort, direction, date, live } = useParams();
  const [lineName, setLineName] = useState(initialLineName);
  const [loadedMainData, setLoadedMainData] = useState(null);
  const [group, setGroup] = useState(0);
  const [switchButton, setSwitchButton] = useState(initialLineName.includes(','));
  const [sharedClass, setSharedClass] = useState('');
  const [sharedNyomvid, setSharedNyomvid] = useState('');
  const [sorrend, setSorrend] = useState('');
  const navigate = useNavigate();
  const { isLoading, sendRequest } = useHttpClient();
  const regex = new RegExp(" \\/ ", "g");
  const validDirection = restrictedLines[lineName] || direction;

//if(switchButton) console.log(switchButton);

  const updateLineName = (newLineName) => {
    setLineName(newLineName);
    navigate(`/${newLineName}/${direction}/1/${live}/${date}`);
  };

  const handleSwitchButton = (event) => {
    const isSwitchOn = event.target.checked;
    setSwitchButton(isSwitchOn);
    setGroup(isSwitchOn ? 1 : 0);
    group === 0 ? updateLineName(formatString(loadedMainData.mainData[0].group_vonal)) : updateLineName(loadedMainData.mainData[0].f_vonal_group)
  };

  useEffect(() => {
    setLineName(initialLineName);
    setSwitchButton(initialLineName.includes(','));
  }, [initialLineName]);

  useEffect(() => {
    const fetchRequest = async () => {
      const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
      const options = {
        url: `${baseUrl}/main-data/${lineName}/${validDirection}/${date}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      try {
        const responseData = await sendRequest(options.url, options.method, null, options.headers);
        if (responseData.success) {
          setLoadedMainData(responseData.data);
        }
      } catch (err) {}
    };
    fetchRequest();
  }, [sendRequest, lineName, validDirection, date]);

  const formatString = (inputString) => {
    return inputString.split(' / ').join(',');
  };

  const buttonStyle2 = {
    display: 'flex',
    backgroundColor: '#ffd200',
    color: '#000',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '20px',
    fontSize: '16px',
    "&:hover": {
      background: "#ffe930",
      border: 'none'
    },
    "@media (max-width: 600px)": {
      fontSize: '14px',
      padding: '10px 15px',
      lineHeight: '20px'
    }
  };

  const labelStyle = {
    fontWeight: '700',
    marginLeft: '10px'
  };


//if(loadedMainData) console.log(loadedMainData.mainData[0].note_o);

  return (
    <div className='main-container1'>
      <div className="line-header">
        {isLoading && (
          <div className="center">
            <LoadingSpinner asOverlay />
          </div>
        )}
        {!isLoading && loadedMainData && (
          <LineHeader
            items={loadedMainData}
            group={group}
            handleSwitchButton={handleSwitchButton}
            lineName={lineName}
            direction={validDirection}
            restrictedLines={restrictedLines}
          />
        )}
      </div>
      <div className='dialog-button-3'>

        <div className="dialog-button-1">
            {!isLoading && loadedMainData && (loadedMainData.mainData[0].name !== loadedMainData.mainData[0].group_name && 
              <FormGroup>
                <FormControlLabel 
                  content='0'
                  className='form-label' 
                  control={<Switch checked={switchButton} onChange={handleSwitchButton} className='switch-button' />} 
                  label={<Typography sx={labelStyle}>VONALCSOPORTOK</Typography>} 
                />
              </FormGroup>
            )}
          </div>

        <div className="dialog-button-2">
        <LineDialog content='0' group={group} switchButton={switchButton} handleSwitchButton={handleSwitchButton}/>
          {!isLoading && loadedMainData && ((switchButton === false) || (switchButton === true && sort)) && (
            <a
              className='yellow-button'
              target='blank_'
              href={`/dkv-basesite/hirdetmeny/${sorrend}_${loadedMainData.mainData[0].group_name.replace(regex, '_').toLowerCase()}_${direction}.pdf`}
              title='Nyomtatható PDF'
            >
              NYOMTATHATÓ PDF
            </a>
          )}
          {loadedMainData && (
            <Link
              to={`/live/${lineName.split(',')[0]}/${lineName === '60h' || lineName === '36e' ? 'v' : 'o'}/1/1/${date}`}
              title='Napi menetrend'
            >
              <Button variant="outlined" sx={buttonStyle2}>Napi menetrend</Button>
            </Link>
          )}
        </div>
      </div>
      <div className='body-container1'>
        <div className='top-side1'>
          <div className='table-container1'>
            <Stops sharedClass={sharedClass} setSharedClass={setSharedClass} sharedNyomvid={sharedNyomvid} setSharedNyomvid={setSharedNyomvid} setSorrend={setSorrend} sorrend={sorrend} />
            {isLoading && (
              <div className="center">
                <LoadingSpinner asOverlay />
              </div>
            )}
          </div>
          <div className="stop-times1">
            <StopTimes sharedClass={sharedClass} sharedNyomvid={sharedNyomvid} />
            {loadedMainData && (
              <TimeDescription note_o={loadedMainData.mainData[0].note_o} note_v={loadedMainData.mainData[0].note_v}/>
            )} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineDetails;