import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useHttpClient } from '../hooks/http-hook';
import LiveLineHeader from '../components/LiveLineHeader';
import LiveStops from '../components/LiveStops';
import LineDialog from '../components/LineDialog';
import './LiveLineDetails.css';
import LiveTimes from '../components/LiveTimes';
import MapComponent from '../components/MapComponent';
import Button from '@mui/material/Button';
import LoadingSpinner from '../components/LoadingSpinner';

const LiveLineDetails = () => {
    window.scrollTo(0, 0);
    const restrictedLines = {
        '60h': 'v',
        '36e': 'v',
        '10a': 'o',
        '125': 'o',
      };

  const { line_name, direction, date, sort, live } = useParams();
  const validDirection = restrictedLines[line_name] || direction; // kivétel!!!!
  const [loadedMainData, setLoadedMainData] = useState();
  const { isLoading, sendRequest } = useHttpClient();
  const [loadedStops, setLoadedStops] = useState();
  const [group, setGroup] = useState(0);
  const navigate = useNavigate();
  
  const buttonStyle2 = {
    display:'flex',
    backgroundColor:'#ffd200',
    color:'#000',
    fontWeight:'bold',
    border:'none',
    borderRadius:'20px',
    fontSize:'16px',
    "&:hover": {
      background: "#ffe930",
      border:'none'
    },
    "@media (max-width: 600px)": {
      fontSize:'14px',
      padding: '10px 15px',
      lineHeight:'20px'
    }
  }

  useEffect(() => {
      const fetchRequest = async () => {
        const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
          const options = {
              url:  `${baseUrl}/main-data/${line_name}/${validDirection}/${date}`,
              method: 'GET',
              body: null,
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              }
          }
          try {
          const responseData = await sendRequest(
              options.url,
              options.method,
              options.body,
              options.headers
          );
          if (responseData.success) {
            setLoadedMainData(responseData.data);
          } else {
            navigate('/');
          }
          } catch (err) {
            navigate('/');
          }
      };
      fetchRequest();
    }, [sendRequest,line_name,validDirection,date,navigate]);

    useEffect(() => {
        const fetchRequest = async () => {
            const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
            const options = {
                url:  `${baseUrl}/travel-time/${line_name}/${validDirection}/${sort}/${live}/${date}`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            if (responseData.success) {
                setLoadedStops(responseData.data);
              } else {
                navigate('/');
              }
              } catch (err) {
                navigate('/');
              }
        };
        fetchRequest();
        }, [sendRequest,line_name,validDirection,sort,live,date,navigate]);

    return (
    <React.Fragment>
                {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
        <div className='main-container'>
            
            {loadedMainData && (
                <LiveLineHeader items={loadedMainData} direction={validDirection} lineName={line_name} restrictedLines={restrictedLines} />
            )}
            
            <div className='dialog-button'>
            <LineDialog content='1'  setGroup={setGroup} group={group} switchButton={null} handleSwitchButton={null} />
                
                <Link to={`/${line_name}/${restrictedLines[line_name] || 'o'}/1/0/${date}`} title='Teljes menetrend'>
                    <Button variant="outlined" sx={buttonStyle2}>Teljes menetrend</Button>
                </Link>
            </div>
            <div className='body-container'>
                <div className='table-container'>
                    {loadedStops && (
                        <LiveStops loadedStops={loadedStops} />
                        )}
                </div>
                <div className="stop-times">
                    <LiveTimes />
                </div>
                <div className="map-content">
                    <MapComponent loadedStops={loadedStops} />
                </div>
            </div>
        </div>
    </React.Fragment>
    );
  }
  
  export default LiveLineDetails;