import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from './LoadingSpinner';
import StopList from './StopList';

const Stops = props => {
    const { line_name, direction, sort, date, live } = useParams();
    const [loadedStops, setLoadedStops] = useState();
    const { isLoading, sendRequest } = useHttpClient();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRequest = async () => {
            const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
            const options = {
                url:  `${baseUrl}/travel-time/${line_name}/${direction}/${sort}/${live}/${date}`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            if (responseData.success) {
                setLoadedStops(responseData.data);
              } else {
                navigate('/');
              }
              } catch (err) {navigate('/');}
        };
        fetchRequest();
        }, [sendRequest,line_name,direction,sort,live,date,navigate]);

        useEffect(() => {
            if (loadedStops) {
                props.setSharedClass(loadedStops.classes);
                props.setSharedNyomvid(loadedStops.f_nyomvid);
                if (loadedStops.travelTime && loadedStops.travelTime.length > 0) {
                    const travelTimeItem = loadedStops.travelTime[sort-1] || loadedStops.travelTime[0];
                    if (travelTimeItem.sorrend2) {
                        props.setSorrend(travelTimeItem.sorrend2);
                    } else {
                        props.setSorrend(travelTimeItem.f_sorrend);
                    }
                }
            }
          }, [props, sort, loadedStops]);

          //console.log(loadedStops.travelTime[0].f_sorrend);
        
    return (
        <React.Fragment>
            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            {loadedStops && (
              <StopList items={loadedStops} />
            )}
        </React.Fragment>
      );

   };
   
   export default Stops;